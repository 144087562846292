<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('aspects.aspects') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('aspects.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-md-6 mb-5">
                <label>{{ $t('aspects.category') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <treeselect
                      :options="categories"
                      :load-options="loadOptions"
                      :multiple="true"
                      :value="data.category_ids"
                      @input="updateValue"
                      :searchable="true"
                      :class="validation && validation.category_ids ? 'is-invalid' : ''"
                      :show-count="true"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :placeholder="$t('Select')">
                  </treeselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.category_ids" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.category_ids[0] }}
                        </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('aspects.attribute_style') }}<span class="text-danger">*</span></label>
                <select name="" id="style_attribute" v-model="data.attribute_style" @input="onChangeAttributeStyle" class="custom-select">
                  <option v-for="row in style_attribute_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.attribute_style" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.attribute_style[0] }}
                            </span>
              </div>

              <div v-if="show_value_type" class="col-lg-6 mb-5">
                <label>{{ $t('aspects.value_type') }}<span class="text-danger">*</span></label>
                <select name="" id="value_type" v-model="data.value_type" class="custom-select">
                  <option v-for="row in value_type_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.value_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.value_type[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="show_repeater" class="row">

        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('aspects.aspect_values') }}</h6>
            <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="form-group">
            <div class="bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th>{{ $t('aspects.name') }}</th>
                  <th>{{ $t('status') }}</th>
                  <th class="text-center">{{ $t("actions") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in aspect_values" :key="index">
                  <td>
                    <input type="text" v-model="it.name" class="form-control" :placeholder="$t('aspects.name')"/>
                  </td>
                  <td>
                    <b-form-checkbox size="lg" v-model="it.is_active" name="check-button" switch></b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('aspects.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('price_lists.notes')"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-aspects",
  data() {
    return {
      mainRoute: 'items/aspects',
      mainRouteDependency: 'base/dependency',
      value_type_list: [],
      style_attribute_list: [],
      categories: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        value_type: 2,
        attribute_style: null,
        category_ids: null,
        notes: null,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      aspect_values_repeater: {id: null, name: null, is_active: true},
      aspect_values: [],
      ids_deleted: [],
      show_repeater: false,
      show_value_type: false,
    };
  },
  methods: {
    onChangeAttributeStyle: _.debounce(function (){
      if (this.data.attribute_style == 1){
        this.show_repeater = false;
        this.aspect_values = [];
        this.show_value_type = true;
      }else{
        this.data.value_type = 2;
        this.show_repeater = true;
        this.show_value_type = false;
        this.addToRepeater();
      }
    },100),
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        aspect_values: this.aspect_values,
        ids_deleted: this.ids_deleted,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/aspects');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        aspect_values: this.aspect_values,
        ids_deleted: this.ids_deleted,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/aspects');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.value_type = response.data.data.value_type;
        this.data.attribute_style = response.data.data.attribute_style;
        this.data.category_ids = response.data.data.category_ids;
        this.data.notes = response.data.data.notes;
        this.data.is_active = response.data.data.is_active;

        this.aspect_values = response.data.data.aspect_values;
        if (response.data.data.aspect_values.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getValueType() {
      ApiService.get(this.mainRouteDependency + "/value_type").then((response) => {
        this.value_type_list = response.data.data;
      });
    },
    getStyleAttribute() {
      ApiService.get(this.mainRouteDependency + "/attribute_style").then((response) => {
        this.style_attribute_list = response.data.data;
      });
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    addToRepeater() {
      this.aspect_values.unshift(this.aspect_values_repeater);
      this.aspect_values_repeater = {id: null, name: null, is_active: true};
    },
    deleteFromRepeater(index) {
      if (this.aspect_values.length > 1) {
        this.ids_deleted.push(this.aspect_values[index].id);
        this.aspect_values.splice(index, 1);
      }
    },


    updateValue(value) {
      this.data.category_ids = value
    },
    loadOptions() {
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.aspects"), route: '/items/aspects'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getValueType();
    this.getStyleAttribute();
    this.getCategories();
    if (this.idEdit) {
      this.getData();
    } else {
      this.addToRepeater();
    }

  },
};
</script>


